/*
 * Copyright 2019 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

@mixin border {
  border:        1px solid $border-color;
  border-radius: $global-border-radius;
}

@mixin row {
  flex-direction:   row;
  flex-basis:       100%;
  align-items:      center;
  justify-content:  space-between;
  flex-wrap:        wrap;
  display:          flex;
  box-sizing:       border-box;
  padding:          5px;
  background-color: $white;
  margin:           10px;
}

@mixin name {
  display:        flex;
  flex-direction: column;
  float:          left;
  align-items:    center;
  padding-left:   10px;
}

@mixin header {
  font-weight: bold;
}

@mixin action-buttons {
  display:     flex;
  align-items: center;
  float:       right;
}

.pipeline-group-row {
  @include row;
  @include border;

  .pipeline-group-name {
    @include name;
    flex-direction: inherit;

    .value {
      @include header;
      margin-left: 5px;
    }
  }


  .pipeline-group-action-buttons {
    @include action-buttons;

    .icon-group-wrapper {
      margin-left: 20px;
    }
  }

  .no-pipelines-defined-message {
    @include row;
  }

  .pipeline-row {
    @include row;
    @include border;

    .pipeline-name {
      @include name;
    }

    .pipeline-action-buttons {
      @include action-buttons;
    }
  }
}




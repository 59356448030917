/*
 * Copyright 2019 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../../global/common";

.test-connection-result {
  margin: 10px 0;
  min-height:  10px;

  &:empty {
    margin-top: 0;
  }
}

.test-connection-button-wrapper {
  width: 100%;
}

@mixin test-connection-button-icon($type, $colors: $icon-light-color, $size: 5px) {
  @include icon-before($type: $type, $color: $colors, $size: 15px, $margin: 0) {
    display:      inline-block;
    margin-right: $size;
    @content
  }
  font-size: 11px;
}

.test-connection-success {
  @include test-connection-button-icon($type: $fa-var-check);
}

.test-connection-failure {
  @include test-connection-button-icon($type: $fa-var-exclamation);
}

.test-connection-in-progress {
  @include test-connection-button-icon($type: $fa-var-spinner) {
    animation: rotate 0.8s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}


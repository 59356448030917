/*
 * Copyright 2019 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../../global/common";

$error-container-width: 450px;

.error-response {
  margin-right: 2ex;
  margin-top: 7px;
  color: $go-danger;
  display: inline-block;
  max-width: $error-container-width;
  white-space: normal;
  float: left;

  &:empty {
    margin-right: 0;
  }

  ol {
    float: left;
    max-width: inherit;
  }
}


/*
 * Copyright 2019 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "~font-awesome/scss/variables";

@mixin icon-only($type, $font: 'font-awesome') {
  @if $font == 'font-awesome' {
    font-family: 'FontAwesome';
    content:     $type;
  }
  @else {
    @error "Could not find font family #{$font}";
  }
}

@mixin icon($type, $size: auto, $margin: auto, $line-height: 1em, $color: auto, $top: auto, $shadow: none, $font: 'font-awesome') {
  @include icon-only($type: $type, $font: $font);

  font-weight:            normal;
  font-style:             normal;
  display:                inline-block;
  text-decoration:        inherit;
  line-height:            $line-height;

  @if $margin != auto {
    margin: $margin;
  }
  @if $size != auto {
    font-size: $size;
  }
  @if $top != auto {
    position: relative;
    top:      $top;
  }
  @if $color != auto {
    color: $color;
  }
  @if $shadow != none {
    text-shadow: $shadow;
  }
  -webkit-font-smoothing: antialiased;
}

@mixin icon-before($type, $size: auto, $margin: 5px, $line-height: 1em, $color: auto, $top: auto, $shadow: none, $font: 'font-awesome', $progress-spinner: false) {
  &:before {
    @include icon($type, $size, $margin, $line-height, $color, $top, $shadow, $font: $font);
    @content;
  }
}

@mixin icon-after($type, $size: auto, $margin: 5px, $line-height: 1em, $color: auto, $top: auto, $shadow: none, $font: 'font-awesome', $progress-spinner: false) {
  &:after {
    @include icon($type, $size, $margin, $line-height, $color, $top, $shadow, $font: $font);
    @content;
  }
}

/*
 * Copyright 2019 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$collapse-icon-size: 25px;

.header-icon {
  display:        none;
  vertical-align: middle;
  margin:         0 15px 0 -15px;
  width:          $collapse-icon-size;
  height:         $collapse-icon-size;
  text-align:     center;

  img {
    width:          $collapse-icon-size;
    height:         $collapse-icon-size;
    vertical-align: middle;
  }

  @media (min-width: $screen-md) {
    display: inline-block;
  }
}

.unknown-icon {
  @include icon-before($type: $fa-var-question, $color: $icon-light-color, $size: 25px, $margin: 0);
  display: block;
}

.no-margin {
  margin: 0;
}
